export default [
  {
    title: 'Contact Information',
    route: 'app-contact-information-index',
    icon: 'ListIcon',
    parent: 'content',
    resource: 'ContactInformation',
    action: 'read',
  },
  {
    title: 'Highlights',
    route: 'app-highlights-index',
    icon: 'StarIcon',
    parent: 'content',
    resource: 'Highlights',
    action: 'read',
  },
  {
    header: 'FAQ',
    icon: 'HelpCircleIcon',
    parent: 'content',
    children: [
      {
        title: 'Articles',
        route: 'app-articles-index',
        icon: '',
        params: {
          type: 'all-article',
        },
        resource: 'Article',
        action: 'read',
      },
      {
        title: 'Article Categories',
        route: 'app-article-categories-index',
        icon: '',
        params: {
          type: 'all-article-category',
        },
        resource: 'ArticleCategory',
        action: 'read',
      },
    ],
  },
]
