<template>
  <li
    v-if="item.title && canViewHorizontalNavMenuLink(item)"
    :class="{
      'active': isActive,
      'disabled': item.disabled
    }"
  >
    <b-link
      v-if="item.title"
      :to="{ name: item.route, params: item.params, query: item.query }"
      class="dropdown-item"
    >
      <feather-icon
        :icon="item.icon"
        size="24"
      />
      <!-- <span class="menu-title">{{ t(item.title) }}</span> -->
      <span class="menu-title">{{ item.title }}</span>
    </b-link>
  </li>
  <li v-else-if="!item.title">
    <hr
      role="separator"
      aria-orientation="horizontal"
      class="dropdown-divider"
    >
  </li>
</template>

<script>
import { ref } from '@vue/composition-api'
import { BLink } from 'bootstrap-vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import { useUtils as useAclUtils } from '@/libs/acl/custom'
import { isNavLinkActive } from '@core/layouts/utils'
import useHorizontalNavMenuLink from '@core/layouts/layout-horizontal/components/horizontal-nav-menu/components/horizontal-nav-menu-link/useHorizontalNavMenuLink'
import mixinHorizontalNavMenuLink from '@core/layouts/layout-horizontal/components/horizontal-nav-menu/components/horizontal-nav-menu-link/mixinHorizontalNavMenuLink'
import router from '@/router'

export default {
  components: {
    BLink,
  },
  mixins: [mixinHorizontalNavMenuLink],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { linkProps } = useHorizontalNavMenuLink(props.item)

    const isActive = ref(false)

    const updateIsActive = () => {
      if (props.item.params) {
        const routeNameActive = isNavLinkActive(props.item)
        // eslint-disable-next-line eqeqeq
        const paramActive = router.currentRoute.params.type == props.item.params.type
        if (routeNameActive && paramActive) {
          isActive.value = true
        } else {
          isActive.value = false
        }
      } else {
        isActive.value = isNavLinkActive(props.item)
      }
    }

    const { t } = useI18nUtils()
    const { canViewHorizontalNavMenuLink } = useAclUtils()

    return {
      isActive,
      linkProps,
      updateIsActive,

      // ACL
      canViewHorizontalNavMenuLink,

      // i18n
      t,
    }
  },

}
</script>
