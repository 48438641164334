<template>
  <ul
    id="main-menu-navigation"
    class="nav navbar-nav"
  >
    <component
      :is="resolveNavComponent(item)"
      v-for="item in items"
      :key="item.key || item.header || item.title"
      :item="item"
    />
    <!-- <li
      class="nav-item justify-content-end ml-auto"
    >
      <b-button
        variant="primary"
        :to="{ name: '' }"
      >
        <feather-icon
          icon="CheckIcon"
          class="mr-50"
          size="16"
        />
        <span class="align-middle">I’ve received goods or services</span>
      </b-button>
    </li> -->
  </ul>
</template>

<script>
import {
  BButton,
} from 'bootstrap-vue'
import HorizontalNavMenuHeaderLink from './HorizontalNavMenuHeaderLink.vue'
import HorizontalNavMenuHeaderGroup from './HorizontalNavMenuHeaderGroup.vue'

export default {
  components: {
    HorizontalNavMenuHeaderLink,
    HorizontalNavMenuHeaderGroup,
    BButton,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const resolveNavComponent = item => {
      if (item.children) return 'horizontal-nav-menu-header-group'
      return 'horizontal-nav-menu-header-link'
    }

    return {
      resolveNavComponent,
    }
  },
}
</script>
