export default [
  {
    title: 'Budgeting',
    icon: 'DollarSignIcon',
    children: [
      {
        title: 'Budget Overview',
        key: 'Budget Overview',
        route: 'budgeting-budget-overview',
        icon: 'PieChartIcon',
        parent: 'budgeting',
        resource: 'BudgetOverview',
        action: 'read',
      },
      {
        title: 'Budget Setup',
        key: 'Budget Setup',
        route: 'budgeting-budget-setup',
        icon: 'SlidersIcon',
        parent: 'budgeting',
        resource: 'BudgetSetup',
        action: 'read',
      },
      {
        title: 'Settings',
        key: 'Budget Settings',
        icon: 'SettingsIcon',
        parent: 'budgeting',
        children: [
          {
            title: 'Expense Categories',
            route: 'budgeting-expense-categories',
            icon: '',
            resource: 'ExpenseCategory',
            action: 'read',
          },
          {
            title: 'Cost Centres',
            route: 'budgeting-cost-center',
            icon: '',
            resource: 'CostCenter',
            action: 'read',
          },
          {
            title: 'Budgetary Periods',
            route: 'budgeting-budgetary-periods',
            icon: '',
            resource: 'BudgetPeriod',
            action: 'read',
          },
        ],
      },
    ],
  },
]
