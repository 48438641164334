export default [
  {
    title: 'Calendar',
    key: 'Operation Calendar',
    route: 'operation-calendar-index',
    icon: 'CalendarIcon',
    parent: 'operations',
    resource: 'OperationCalendar',
    action: 'read',
  },
  {
    header: 'Service Forms',
    icon: 'LayoutIcon',
    parent: 'operations',
    children: [
      {
        title: 'Pending',
        route: 'operation-service-forms-index',
        icon: '',
        params: {
          type: 'pending',
        },
        resource: 'ServiceForm',
        action: 'read',
      },
      {
        title: 'Awaiting Approval',
        route: 'operation-service-forms-index',
        icon: '',
        params: {
          type: 'pending-approval',
        },
        resource: 'ServiceForm',
        action: 'read',
      },
      {
        title: 'Completed',
        route: 'operation-service-forms-index',
        icon: '',
        params: {
          type: 'completed',
        },
        resource: 'ServiceForm',
        action: 'read',
      },
      // {
      //   title: 'Draft',
      //   route: 'operation-service-forms-index',
      //   icon: '',
      //   params: {
      //     type: 'draft',
      //   },
      //   resource: 'ServiceForm',
      //   action: 'read',
      // },
      {
        title: 'All Service Forms',
        route: 'operation-service-forms-index',
        icon: '',
        params: {
          type: 'all-service-forms',
        },
        resource: 'ServiceForm',
        action: 'read',
      },
      {
        title: '',
        route: '',
        icon: '',
      },
      {
        title: 'New Service Form',
        route: 'operation-service-forms-create',
        resource: 'ServiceForm',
        icon: '',
        action: 'create',
      },
    ],
  },
  {
    header: 'Service Requests',
    icon: 'CheckCircleIcon',
    parent: 'operations',
    children: [
      {
        title: 'Pending',
        route: 'operation-service-requests-index',
        icon: '',
        params: {
          type: 'pending',
        },
        resource: 'ServiceRequest',
        action: 'read',
      },
      {
        title: 'Completed',
        route: 'operation-service-requests-index',
        icon: '',
        params: {
          type: 'completed',
        },
        resource: 'ServiceRequest',
        action: 'read',
      },
      {
        title: 'Unassigned',
        route: 'operation-service-requests-index',
        icon: '',
        params: {
          type: 'unassigned',
        },
        resource: 'ServiceRequest',
        action: 'read',
      },
      {
        title: 'Assigned To Me',
        route: 'operation-service-requests-index',
        icon: '',
        params: {
          type: 'my-requests',
        },
        resource: 'ServiceRequest',
        action: 'read',
      },
      {
        title: 'All Service Requests',
        route: 'operation-service-requests-index',
        icon: '',
        params: {
          type: 'all-service-requests',
        },
        resource: 'ServiceRequest',
        action: 'read',
      },
      {
        title: '',
        route: '',
        icon: '',
        key: 'first',
      },
      {
        title: 'Kitchen’s View',
        route: 'operation-service-requests-kitchen-view',
        icon: '',
        params: {
          type: 'all-service-requests',
        },
        resource: 'ServiceRequest',
        action: 'kitchen-view',
      },
      {
        title: '',
        route: '',
        icon: '',
        key: 'second',
      },
      {
        title: 'New Service Request',
        route: 'operation-service-requests-create',
        resource: 'ServiceRequest',
        icon: '',
        action: 'create',
      },
    ],
  },
  {
    title: 'Tasks',
    route: 'operation-service-requests-tasks-index',
    icon: 'FileTextIcon',
    parent: 'operations',
    resource: 'ServiceRequestTask',
    action: 'read',
  },
  {
    header: 'Roster',
    icon: 'CalendarIcon',
    parent: 'operations',
    children: [
      {
        title: 'Roster Groups',
        route: 'operation-roster-groups-index',
        icon: '',
        query: '',
        resource: 'RosterGroup',
        action: 'read',
      },
      {
        title: 'Status Types',
        route: 'operation-roster-status-types-index',
        icon: '',
        query: '',
        resource: 'RosterStatusType',
        action: 'read',
      },
      {
        title: 'Annual Grouping',
        route: 'operation-roster-annual-groupings-index',
        icon: '',
        query: '',
        resource: 'RosterYear',
        action: 'read',
      },
      {
        title: 'Monthly Roster',
        route: 'operation-roster-monthly-index',
        icon: '',
        query: '',
        resource: 'RosterMonth',
        action: 'read',
      },
    ],
  },
  {
    header: 'Reports',
    key: 'Operation Reports',
    icon: 'PieChartIcon',
    parent: 'operations',
    children: [
      {
        title: 'Ceremonial Services',
        route: 'operation-ceremonial-services-report',
        query: '',
        icon: '',
        resource: 'OperationReport',
        action: 'ceremonial-service',
      },
      {
        title: 'Call Logs Reports',
        route: 'operation-call-logs-report',
        query: '',
        icon: '',
        resource: 'OperationReport',
        action: 'call-log',
      },
      {
        title: 'Appointments Reports',
        route: 'operation-appointments-report',
        query: '',
        icon: '',
        resource: 'OperationReport',
        action: 'appointments',
      },
      {
        title: 'Site Ops Urn Internment Reports',
        route: 'operation-site-ops-urn-internment-report',
        query: '',
        icon: '',
        resource: 'OperationReport',
        action: 'site-ops-urn-internment',
      },
      // {
      //   title: 'Customer Feedback Reports',
      //   route: 'operation-customer-feedback-report',
      //   query: '',
      //   icon: '',
      //   resource: 'OperationReport',
      //   action: 'customer-feedback',
      // },
    ],
  },
  {
    header: 'Settings',
    key: 'Operation Settings',
    icon: 'SettingsIcon',
    parent: 'operations',
    children: [
      {
        title: 'Task Block Components',
        route: 'operation-task-block-components-index',
        icon: 'HexagonIcon',
        query: '',
        resource: 'TaskBlockComponent',
        action: 'read',
      },
      {
        title: 'Workflow Category',
        route: 'operation-workflow-category-index',
        icon: 'AwardIcon',
        resource: 'WorkFlowCategory',
        action: 'read',
      },
      {
        title: 'Workflows',
        route: 'operation-workflows-index',
        icon: 'ApertureIcon',
        params: {
          type: 'all-work-flows',
        },
        resource: 'WorkFlow',
        action: 'read',
      },
      {
        title: 'Service Description',
        route: 'operation-service-descriptions',
        icon: 'AlignJustifyIcon',
        params: {},
        resource: 'ServiceDescription',
        action: 'create',
      },
      {
        title: 'Form Templates',
        route: 'operation-digital-form-index',
        icon: 'FileTextIcon',
        query: '',
        resource: 'FormTemplate',
        action: 'read',
      },
    ],
  },
]
