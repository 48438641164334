<template>
  <b-navbar-nav class="nav">
    <b-nav-item
      v-for="(bookmark, index) in bookmarks"
      :id="`bookmark-${index}`"
      :key="index"
      :to="bookmark.route"
    >
      <feather-icon
        :icon="bookmark.icon"
        size="21"
        :class="{'rotate-icon': bookmark.rotateIcon}"
      />
      <b-tooltip
        triggers="hover"
        :target="`bookmark-${index}`"
        :title="bookmark.title"
        :delay="{ show: 50, hide: 50 }"
      />
    </b-nav-item>
  </b-navbar-nav>
</template>

<script>
import {
  BNavbarNav, BNavItem, BTooltip,
} from 'bootstrap-vue'
import useAutoSuggest from '@core/components/app-auto-suggest/useAutoSuggest'
import { ref, watch } from '@vue/composition-api'
import { useUtils as useAclUtils } from '@/libs/acl/custom'
import router from '@/router'
import store from '@/store'
import appointmentMenuItems from '@/navigation/horizontal/appointment'
import directoryMenuItems from '@/navigation/horizontal/directory'
import operationMenuItems from '@/navigation/horizontal/operation'
import purchaseMenuItems from '@/navigation/horizontal/purchase'
import inventoryMenuItems from '@/navigation/horizontal/inventory'
import budgetMenuItems from '@/navigation/horizontal/budget'
import dashboardMenuItems from '@/navigation/horizontal/dashboard'
import appMenuItems from '@/navigation/horizontal/app'
import customerMenuItems from '@/navigation/horizontal/customer'
import agencyMenuItems from '@/navigation/horizontal/agency'
import searchAndBookmarkData from '../search-and-bookmark-data'

export default {
  components: {
    BNavbarNav, BNavItem, BTooltip,
  },
  setup() {
    const { canViewHorizontalNavMenuHeaderGroup, canViewHorizontalNavMenuHeaderLink } = useAclUtils()
    const searchAndBookmarkDataPages = ref(searchAndBookmarkData.pages)
    const bookmarks = ref(searchAndBookmarkData.pages.data.filter(page => {
      let canView = false
      // let routeName = ''
      if (page.title === 'Appointments') {
        // appointmentMenuItems.forEach(item => {
        //   if (item.children) {
        //     if (canViewHorizontalNavMenuHeaderGroup(item) === true) {
        //       canView = true
        //     }
        //   } else if (canViewHorizontalNavMenuHeaderLink(item) === true) {
        //     canView = true
        //   }
        // })
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < appointmentMenuItems.length; i++) {
          if (appointmentMenuItems[i].children) {
            if (canViewHorizontalNavMenuHeaderGroup(appointmentMenuItems[i]) === true) {
              canView = true
              // eslint-disable-next-line no-plusplus
              for (let j = 0; j < appointmentMenuItems[i].children.length; j++) {
                if (canViewHorizontalNavMenuHeaderLink(appointmentMenuItems[i].children[j]) === true) {
                  // eslint-disable-next-line no-param-reassign
                  page.route = { name: appointmentMenuItems[i].children[j].route, params: appointmentMenuItems[i].children[j].params, query: appointmentMenuItems[i].children[j].query }
                  break
                }
              }
              break
            }
          } else if (canViewHorizontalNavMenuHeaderLink(appointmentMenuItems[i]) === true) {
            canView = true
            // eslint-disable-next-line no-param-reassign
            page.route = { name: appointmentMenuItems[i].route, params: appointmentMenuItems[i].params, query: appointmentMenuItems[i].query }
            break
          }
        }
      } else if (page.title === 'Operations') {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < operationMenuItems.length; i++) {
          if (operationMenuItems[i].children) {
            if (canViewHorizontalNavMenuHeaderGroup(operationMenuItems[i]) === true) {
              canView = true
              // eslint-disable-next-line no-plusplus
              for (let j = 0; j < operationMenuItems[i].children.length; j++) {
                if (canViewHorizontalNavMenuHeaderLink(operationMenuItems[i].children[j]) === true) {
                  // eslint-disable-next-line no-param-reassign
                  page.route = { name: operationMenuItems[i].children[j].route, params: operationMenuItems[i].children[j].params, query: operationMenuItems[i].children[j].query }
                  break
                }
              }
              break
            }
          } else if (canViewHorizontalNavMenuHeaderLink(operationMenuItems[i]) === true) {
            canView = true
            // eslint-disable-next-line no-param-reassign
            page.route = { name: operationMenuItems[i].route, params: operationMenuItems[i].params, query: operationMenuItems[i].query }
            break
          }
        }
      } else if (page.title === 'Purchasing') {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < purchaseMenuItems.length; i++) {
          if (purchaseMenuItems[i].children) {
            if (canViewHorizontalNavMenuHeaderGroup(purchaseMenuItems[i]) === true) {
              canView = true
              // eslint-disable-next-line no-plusplus
              for (let j = 0; j < purchaseMenuItems[i].children.length; j++) {
                if (canViewHorizontalNavMenuHeaderLink(purchaseMenuItems[i].children[j]) === true) {
                  // eslint-disable-next-line no-param-reassign
                  page.route = { name: purchaseMenuItems[i].children[j].route, params: purchaseMenuItems[i].children[j].params, query: purchaseMenuItems[i].children[j].query }
                  break
                }
              }
              break
            }
          } else if (canViewHorizontalNavMenuHeaderLink(purchaseMenuItems[i]) === true) {
            canView = true
            // eslint-disable-next-line no-param-reassign
            page.route = { name: purchaseMenuItems[i].route, params: purchaseMenuItems[i].params, query: purchaseMenuItems[i].query }
            break
          }
        }
      } else if (page.title === 'Directory') {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < directoryMenuItems.length; i++) {
          if (directoryMenuItems[i].children) {
            if (canViewHorizontalNavMenuHeaderGroup(directoryMenuItems[i]) === true) {
              canView = true
              // eslint-disable-next-line no-plusplus
              for (let j = 0; j < directoryMenuItems[i].children.length; j++) {
                if (canViewHorizontalNavMenuHeaderLink(directoryMenuItems[i].children[j]) === true) {
                  // eslint-disable-next-line no-param-reassign
                  page.route = { name: directoryMenuItems[i].children[j].route, params: directoryMenuItems[i].children[j].params, query: directoryMenuItems[i].children[j].query }
                  break
                }
              }
              break
            }
          } else if (canViewHorizontalNavMenuHeaderLink(directoryMenuItems[i]) === true) {
            canView = true
            // eslint-disable-next-line no-param-reassign
            page.route = { name: directoryMenuItems[i].route, params: directoryMenuItems[i].params, query: directoryMenuItems[i].query }
            break
          }
        }
      } else if (page.title === 'Inventory') {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < inventoryMenuItems.length; i++) {
          if (inventoryMenuItems[i].children) {
            if (canViewHorizontalNavMenuHeaderGroup(inventoryMenuItems[i]) === true) {
              canView = true
              // eslint-disable-next-line no-plusplus
              for (let j = 0; j < inventoryMenuItems[i].children.length; j++) {
                if (canViewHorizontalNavMenuHeaderLink(inventoryMenuItems[i].children[j]) === true) {
                  // eslint-disable-next-line no-param-reassign
                  page.route = { name: inventoryMenuItems[i].children[j].route, params: inventoryMenuItems[i].children[j].params, query: inventoryMenuItems[i].children[j].query }
                  break
                }
              }
              break
            }
          } else if (canViewHorizontalNavMenuHeaderLink(inventoryMenuItems[i]) === true) {
            canView = true
            // eslint-disable-next-line no-param-reassign
            page.route = { name: inventoryMenuItems[i].route, params: inventoryMenuItems[i].params, query: inventoryMenuItems[i].query }
            break
          }
        }
      } else if (page.title === 'Budgeting') {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < budgetMenuItems.length; i++) {
          if (budgetMenuItems[i].children) {
            if (canViewHorizontalNavMenuHeaderGroup(budgetMenuItems[i]) === true) {
              canView = true
              // eslint-disable-next-line no-plusplus
              for (let j = 0; j < budgetMenuItems[i].children.length; j++) {
                if (canViewHorizontalNavMenuHeaderLink(budgetMenuItems[i].children[j]) === true) {
                  // eslint-disable-next-line no-param-reassign
                  page.route = { name: budgetMenuItems[i].children[j].route, params: budgetMenuItems[i].children[j].params, query: budgetMenuItems[i].children[j].query }
                  break
                }
              }
              break
            }
          } else if (canViewHorizontalNavMenuHeaderLink(budgetMenuItems[i]) === true) {
            canView = true
            // eslint-disable-next-line no-param-reassign
            page.route = { name: budgetMenuItems[i].route, params: budgetMenuItems[i].params, query: budgetMenuItems[i].query }
            break
          }
        }
      } else if (page.title === 'My Dashboard') {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < dashboardMenuItems.length; i++) {
          if (dashboardMenuItems[i].children) {
            if (canViewHorizontalNavMenuHeaderGroup(dashboardMenuItems[i]) === true) {
              canView = true
              // eslint-disable-next-line no-plusplus
              for (let j = 0; j < dashboardMenuItems[i].children.length; j++) {
                if (canViewHorizontalNavMenuHeaderLink(dashboardMenuItems[i].children[j]) === true) {
                  // eslint-disable-next-line no-param-reassign
                  page.route = { name: dashboardMenuItems[i].children[j].route, params: dashboardMenuItems[i].children[j].params, query: dashboardMenuItems[i].children[j].query }
                  break
                }
              }
              break
            }
          } else if (canViewHorizontalNavMenuHeaderLink(dashboardMenuItems[i]) === true) {
            canView = true
            // eslint-disable-next-line no-param-reassign
            page.route = { name: dashboardMenuItems[i].route, params: dashboardMenuItems[i].params, query: dashboardMenuItems[i].query }
            break
          }
        }
      } else if (page.title === 'Content') {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < appMenuItems.length; i++) {
          if (appMenuItems[i].children) {
            if (canViewHorizontalNavMenuHeaderGroup(appMenuItems[i]) === true) {
              canView = true
              // eslint-disable-next-line no-plusplus
              for (let j = 0; j < appMenuItems[i].children.length; j++) {
                if (canViewHorizontalNavMenuHeaderLink(appMenuItems[i].children[j]) === true) {
                  // eslint-disable-next-line no-param-reassign
                  page.route = { name: appMenuItems[i].children[j].route, params: appMenuItems[i].children[j].params, query: appMenuItems[i].children[j].query }
                  break
                }
              }
              break
            }
          } else if (canViewHorizontalNavMenuHeaderLink(appMenuItems[i]) === true) {
            canView = true
            // eslint-disable-next-line no-param-reassign
            page.route = { name: appMenuItems[i].route, params: appMenuItems[i].params, query: appMenuItems[i].query }
            break
          }
        }
      } else if (page.title === 'Customers') {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < customerMenuItems.length; i++) {
          if (customerMenuItems[i].children) {
            if (canViewHorizontalNavMenuHeaderGroup(customerMenuItems[i]) === true) {
              canView = true
              // eslint-disable-next-line no-plusplus
              for (let j = 0; j < customerMenuItems[i].children.length; j++) {
                if (canViewHorizontalNavMenuHeaderLink(customerMenuItems[i].children[j]) === true) {
                  // eslint-disable-next-line no-param-reassign
                  page.route = { name: customerMenuItems[i].children[j].route, params: customerMenuItems[i].children[j].params, query: customerMenuItems[i].children[j].query }
                  break
                }
              }
              break
            }
          } else if (canViewHorizontalNavMenuHeaderLink(customerMenuItems[i]) === true) {
            canView = true
            // eslint-disable-next-line no-param-reassign
            page.route = { name: customerMenuItems[i].route, params: customerMenuItems[i].params, query: customerMenuItems[i].query }
            break
          }
        }
      } else if (page.title === 'Agencies') {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < agencyMenuItems.length; i++) {
          if (agencyMenuItems[i].children) {
            if (canViewHorizontalNavMenuHeaderGroup(agencyMenuItems[i]) === true) {
              canView = true
              // eslint-disable-next-line no-plusplus
              for (let j = 0; j < agencyMenuItems[i].children.length; j++) {
                if (canViewHorizontalNavMenuHeaderLink(agencyMenuItems[i].children[j]) === true) {
                  // eslint-disable-next-line no-param-reassign
                  page.route = { name: agencyMenuItems[i].children[j].route, params: agencyMenuItems[i].children[j].params, query: agencyMenuItems[i].children[j].query }
                  break
                }
              }
              break
            }
          } else if (canViewHorizontalNavMenuHeaderLink(agencyMenuItems[i]) === true) {
            canView = true
            // eslint-disable-next-line no-param-reassign
            page.route = { name: agencyMenuItems[i].route, params: agencyMenuItems[i].params, query: agencyMenuItems[i].query }
            break
          }
        }
      }
      return (page.isBookmarked && canView)
    }))
    const currentSelected = ref(-1)

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
    }

    const {
      searchQuery,
      resetsearchQuery,
      filteredData,
    } = useAutoSuggest({ data: { pages: searchAndBookmarkDataPages.value }, searchLimit: 6 })

    watch(searchQuery, val => {
      store.commit('app/TOGGLE_OVERLAY', Boolean(val))
    })

    watch(filteredData, val => {
      currentSelected.value = val.pages && !val.pages.length ? -1 : 0
    })

    const suggestionSelected = () => {
      const suggestion = filteredData.value.pages[currentSelected.value]
      router.push(suggestion.route).catch(() => {})
      resetsearchQuery()
    }

    const toggleBookmarked = item => {
      // Find Index of item/page in bookmarks' array
      const pageIndexInBookmarks = bookmarks.value.findIndex(i => i.route === item.route)

      // If index is > -1 => Item is bookmarked => Remove item from bookmarks array using index
      // Else => Item is not bookmarked => Add item to bookmarks' array
      if (pageIndexInBookmarks > -1) {
        bookmarks.value[pageIndexInBookmarks].isBookmarked = false
        bookmarks.value.splice(pageIndexInBookmarks, 1)
      } else {
        bookmarks.value.push(item)
        bookmarks.value[bookmarks.value.length - 1].isBookmarked = true
      }
    }

    return {
      bookmarks,
      perfectScrollbarSettings,
      currentSelected,
      suggestionSelected,
      toggleBookmarked,

      // AutoSuggest
      searchQuery,
      resetsearchQuery,
      filteredData,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';

ul
{
  list-style: none;
  padding: 0;
  margin: 0;
}
p {
  margin: 0;
}

.nav-bookmar-content-overlay {
    position: fixed;
    opacity: 0;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-transition: all 0.7s;
    transition: all 0.7s;
    z-index: -1;

    &:not(.show) {
      pointer-events: none;
    }

    &.show {
      cursor: pointer;
      z-index: 10;
      opacity: 1;
    }
}
</style>
