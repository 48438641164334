export default [
  {
    title: 'Stock List',
    key: 'Stock List',
    route: 'inventory-stock-list',
    icon: 'BoxIcon',
    parent: 'inventory',
    resource: ['StockList', 'StockList'],
    action: ['read', 'list-view-all'],
  },
  {
    header: 'Master List',
    icon: 'ListIcon',
    parent: 'inventory',
    children: [
      {
        title: 'Inventory Items',
        route: 'inventory-inventory-items-list',
        icon: '',
        resource: 'InventoryItem',
        action: 'read',
      },
      {
        title: 'Stores',
        route: 'inventory-store-list',
        icon: '',
        resource: 'InventoryStore',
        action: 'read',
      },
      {
        title: 'Categories',
        route: 'inventory-category-list',
        resource: 'InventoryCategory',
        icon: '',
        action: 'read',
      },
    ],
  },
  {
    title: 'Inventory Requests',
    key: 'Inventory Requests',
    route: 'inventory-inventory-requests-index',
    params: {
      type: 'all-requests',
    },
    icon: 'BoxIcon',
    parent: 'inventory',
    resource: 'InventoryRequest',
    action: 'read',
  },
  {
    header: 'Reports',
    icon: 'FileTextIcon',
    parent: 'inventory',
    children: [
      {
        title: 'Urn Stock Movement Report',
        route: 'inventory-urn-stock-movement-report',
        icon: '',
        resource: 'InventoryReports',
        action: 'urn-stock-movement',
      },
    ],
  },
  {
    title: 'History',
    key: 'History',
    route: 'inventory-inventory-history',
    icon: 'ClockIcon',
    parent: 'inventory',
    resource: 'InventoryTransaction',
    action: 'read',
  },
]
