<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template #button-content>
      <div class="d-sm-flex d-none user-nav">
        <p class="user-name font-weight-bolder mb-0">
          {{ userData.name || userData.email }}
        </p>
        <span class="user-status">{{ userData.position }}, {{ userData.department.length ? userData.department.map(({ name }) => name).join(' | ') : '' }}</span>
      </div>
      <b-avatar
        size="40"
        :src="proImage"
        variant="light-primary"
      >
        <feather-icon
          v-if="!userData.name"
          icon="UserIcon"
          size="22"
        />
      </b-avatar>
    </template>

    <!-- <b-dropdown-item
      :to="{ name: 'apps-email' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="MailIcon"
        class="mr-50"
      />
      <span>Inbox</span>
    </b-dropdown-item>

    <b-dropdown-divider /> -->

    <b-dropdown-item
      link-class="d-flex align-items-center"
      :to="{ name: 'directory-my-profile' }"
    >
      <feather-icon
        size="16"
        icon="UserIcon"
        class="mr-50"
      />
      <span>My Profile</span>
    </b-dropdown-item>

    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click="logout"
    >
      <feather-icon
        size="16"
        icon="LogOutIcon"
        class="mr-50"
      />
      <span>Logout</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BDropdownItem, BAvatar,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { initialAbility } from '@/libs/acl/config'
// import useJwt from '@/auth/jwt/useJwt'
import store from '@/store/index'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    // BDropdownDivider,
    BAvatar,
  },
  data() {
    return {
      userData: store.state.auth.userData,
      avatarText,
    }
  },
  computed: {
    proImage() {
      return store.state.auth.userData.image
    },
  },
  methods: {
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      // localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      // localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

      // // Remove userData from localStorage
      // localStorage.removeItem('userData')
      store
        .dispatch('auth/logout')
        .then(response => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: response.data.message ?? '',
              icon: 'BellIcon',
              variant: 'success',
            },
          })

          // Reset ability
          this.$ability.update(initialAbility)

          // Redirect to login page
          this.$router.push({ name: 'auth-login' })
        })
    },
  },
}
</script>
