export default [
  {
    title: 'Directory',
    icon: 'FileIcon',
    children: [
      {
        title: 'Users',
        route: 'directory-users-index',
        icon: 'UserIcon',
        parent: 'directory',
        resource: 'User',
        action: 'read',
      },
      // {
      //   title: 'App Clients',
      //   route: 'directory-app-clients-index',
      //   icon: 'SmartphoneIcon',
      //   parent: 'directory',
      //   resource: 'AppClient',
      //   action: 'read',
      // },
      {
        title: 'Roles',
        route: 'directory-roles-index',
        icon: 'AnchorIcon',
        parent: 'directory',
        resource: 'Role',
        action: 'read',
      },
      {
        title: 'Business Entities',
        route: 'directory-business-entities-index',
        icon: 'BookIcon',
        parent: 'directory',
        resource: 'BusinessEntity',
        action: 'read',
      },
      {
        title: 'Departments',
        route: 'directory-departments-index',
        icon: 'Share2Icon',
        parent: 'directory',
        resource: 'Department',
        action: 'read',
      },
      {
        title: 'Addresses',
        route: 'directory-addresses-index',
        icon: 'MapPinIcon',
        parent: 'directory',
        resource: 'Address',
        action: 'read',
      },
      {
        title: 'Teams',
        route: 'directory-teams-index',
        icon: 'CoffeeIcon',
        parent: 'directory',
        resource: 'Team',
        action: 'read',
      },
    ],
  },
]
