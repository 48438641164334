<template>
  <core-layout-horizontal>

    <router-view />

    <app-customizer
      v-if="showCustomizer"
      slot="customizer"
    />
  </core-layout-horizontal>

</template>

<script>
import AppCustomizer from '@core/layouts/components/app-customizer/AppCustomizer.vue'
import { $themeConfig } from '@themeConfig'
import CoreLayoutHorizontal from './CoreLayoutHorizontal.vue'

export default {
  components: {
    CoreLayoutHorizontal,
    AppCustomizer,
  },
  data() {
    return {
      showCustomizer: $themeConfig.layout.customizer,
    }
  },
}
</script>
