export default [
  {
    title: 'My Dashboard',
    icon: 'GridIcon',
    children: [
      {
        title: 'My Dashboard',
        route: 'dashboard',
        icon: 'GridIcon',
        resource: 'Dashboard',
        action: 'my-dashboard',
        parent: 'dashboard',
      },
      {
        title: 'Settings',
        key: 'Dashboard Settings',
        icon: 'SettingsIcon',
        parent: 'dashboard',
        children: [
          {
            title: 'Dashboard Views',
            route: 'dashboard-views-index',
            icon: '',
            query: '',
            resource: 'Dashboard',
            action: 'read',
          },
        ],
      },
    ],
  },
]
