export default [
  {
    title: 'Agents',
    route: 'agency-agents-index',
    icon: 'UsersIcon',
    resource: 'Agent',
    action: 'read',
    parent: 'agencies',
  },
]
