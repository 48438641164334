export default [
  {
    title: 'Customers',
    icon: 'UsersIcon',
    children: [
      {
        title: 'Files',
        route: 'customers-files-index',
        icon: 'FileIcon',
        resource: 'File',
        action: 'read',
        parent: 'customers',
      },
      {
        title: 'Customers',
        route: 'customers-customers-index',
        icon: 'UsersIcon',
        resource: 'Customer',
        action: 'read',
        parent: 'customers',
      },
      {
        title: 'Invoices',
        route: 'customers-invoices-index',
        icon: 'FileIcon',
        parent: 'customers',
        resource: 'Invoice',
        action: 'read',
      },
      {
        title: 'Payments/Receipts',
        route: 'customers-receipts-index',
        icon: 'CreditCardIcon',
        parent: 'customers',
        resource: 'Receipt',
        action: 'read',
      },
      {
        title: 'Customer Requests',
        route: 'app-customer-profile-update-index',
        icon: 'AlertCircleIcon',
        parent: 'customers',
        resource: 'CustomerRequest',
        action: 'read',
      },
    ],
  },
]
