<template>
  <li
    v-if="item.title && canViewVerticalNavMenuLink(item)"
    class="nav-item"
    :class="{
      'active': isActive,
      'disabled': item.disabled
    }"
  >
    <b-link
      v-if="item.title"
      :to="{ name: item.route, params: item.params, query: item.query }"
      class="d-flex align-items-center"
    >
      <feather-icon :icon="item.icon" />
      <span class="menu-title text-truncate">{{ item.title }}</span>
      <b-badge
        v-if="item.tag"
        pill
        :variant="item.tagVariant || 'primary'"
        class="mr-1 ml-auto"
      >
        {{ item.tag }}
      </b-badge>
    </b-link>
  </li>
  <li v-else-if="!item.title">
    <hr
      role="separator"
      aria-orientation="horizontal"
      class="dropdown-divider"
    >
  </li>
</template>

<script>
import { ref } from '@vue/composition-api'
import { useUtils as useAclUtils } from '@/libs/acl/custom'
import { BLink, BBadge } from 'bootstrap-vue'
import { isNavLinkActive } from '@core/layouts/utils'
import router from '@/router'
import useVerticalNavMenuLink from './useVerticalNavMenuLink'
import mixinVerticalNavMenuLink from './mixinVerticalNavMenuLink'

export default {
  components: {
    BLink,
    BBadge,
  },
  mixins: [mixinVerticalNavMenuLink],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { linkProps } = useVerticalNavMenuLink(props.item)
    const { canViewVerticalNavMenuLink } = useAclUtils()

    const isActive = ref(false)

    const updateIsActive = () => {
      if (props.item.params) {
        const routeNameActive = isNavLinkActive(props.item)
        // eslint-disable-next-line eqeqeq
        const paramActive = router.currentRoute.params.type == props.item.params.type
        if (routeNameActive && paramActive) {
          isActive.value = true
        } else {
          isActive.value = false
        }
      } else {
        isActive.value = isNavLinkActive(props.item)
      }
    }

    return {
      isActive,
      linkProps,
      updateIsActive,

      // ACL
      canViewVerticalNavMenuLink,
    }
  },

}
</script>
