export default [
  {
    title: 'Calendar',
    route: 'appointment-calendar-index',
    icon: 'CalendarIcon',
    parent: 'appointments',
    resource: 'Calendar',
    action: 'read',
  },
  {
    header: 'Bookings',
    icon: 'BookOpenIcon',
    parent: 'appointments',
    children: [
      {
        title: 'Pending Approval',
        route: 'appointment-bookings-index',
        icon: '',
        params: {
          type: 'pending-approval',
        },
        resource: 'Booking',
        action: 'read',
      },
      {
        title: 'Pending Payment',
        route: 'appointment-bookings-index',
        icon: '',
        params: {
          type: 'pending-payment',
        },
        resource: 'Booking',
        action: 'read',
      },
      {
        title: 'Confirmed',
        route: 'appointment-bookings-index',
        icon: '',
        params: {
          type: 'confirmed',
        },
        resource: 'Booking',
        action: 'read',
      },
      {
        title: 'Waiting List',
        route: 'appointment-bookings-index',
        icon: '',
        params: {
          type: 'waiting-list',
        },
        resource: 'Booking',
        action: 'read',
      },
      {
        title: 'All Bookings',
        route: 'appointment-bookings-index',
        icon: '',
        params: {
          type: 'all-booking',
        },
        resource: 'Booking',
        action: 'read',
      },
      {
        title: '',
        route: '',
        icon: '',
      },
      {
        title: 'New Booking',
        route: 'appointment-bookings-create',
        resource: 'Booking',
        icon: '',
        action: 'create',
      },
    ],
  },
  {
    header: 'Call Logs',
    icon: 'PhoneCallIcon',
    parent: 'appointments',
    children: [
      {
        title: 'All Call Logs',
        route: 'appointment-call-logs-index',
        icon: '',
        query: '',
        resource: 'CallLog',
        action: 'read',
      },
      {
        title: '',
        route: '',
        icon: '',
      },
      {
        title: 'New Call Log',
        route: 'appointment-call-logs-create',
        resource: 'CallLog',
        icon: '',
        action: 'create',
      },
    ],
  },
  {
    header: 'Events',
    icon: 'GridIcon',
    parent: 'appointments',
    children: [
      {
        title: 'Published',
        route: 'appointment-events-index',
        icon: '',
        params: {
          type: 'published',
        },
        resource: 'Event',
        action: 'read',
      },
      {
        title: 'Unpublished',
        route: 'appointment-events-index',
        icon: '',
        params: {
          type: 'unpublished',
        },
        resource: 'Event',
        action: 'read',
      },
      {
        title: 'Draft',
        route: 'appointment-events-index',
        icon: '',
        params: {
          type: 'draft',
        },
        resource: 'Event',
        action: 'read',
      },
      {
        title: 'All Events',
        route: 'appointment-events-index',
        icon: '',
        params: {
          type: 'all-event',
        },
        resource: 'Event',
        action: 'read',
      },
      {
        title: '',
        route: '',
        icon: '',
      },
      {
        title: 'New Event',
        route: 'appointment-events-create',
        resource: 'Event',
        icon: '',
        action: 'create',
      },
    ],
  },
  {
    header: 'Settings',
    icon: 'SettingsIcon',
    parent: 'appointments',
    children: [
      {
        title: 'Block Out Dates',
        route: 'appointment-block-out-dates-index',
        icon: 'ShieldOffIcon',
        query: '',
        resource: 'BlockOutDate',
        action: 'read',
      },
      {
        title: 'Activity Types',
        route: 'appointment-activity-types-index',
        icon: 'ActivityIcon',
        query: '',
        resource: 'ActivityType',
        action: 'read',
      },
      {
        title: 'Call Log Types',
        route: 'appointment-call-log-types-index',
        icon: 'PhoneCallIcon',
        query: '',
        resource: 'CallLogType',
        action: 'read',
      },
    ],
  },
]
