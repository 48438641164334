export default [
  {
    title: 'Agencies',
    icon: 'Share2Icon',
    children: [
      {
        title: 'Agents',
        route: 'agency-agents-index',
        icon: 'UsersIcon',
        resource: 'Agent',
        action: 'read',
        parent: 'agencies',
      },
    ],
  },
]
